<template>
  <section class="space-y-4">
    <quick-info />
    <article v-if="canCreateSCCF">
      <h3 class="subheader">
        Quick Links
      </h3>
      <br />
      <section class="relative z-0  rounded-md grid sm:grid-cols-1 md:grid-cols-4">
        <asom-button
          variant="primary"
          outline
          rounded="none"
          @click="create"
          icon="duplicate"
          text="Create SCCF"
        >
        </asom-button>
      </section>
    </article>
    <!-- filter begin-->
    <asom-card title="SCCF Search">
      <div class="grid sm:grid-cols-1 md:grid-cols-2 md:gap-x-6 gap-4">
        <asom-form-field
          label="Date Created"
          required
          :state="inputStates('filters.dateRange')"
        >
          <asom-input-date-range
            v-model="filters.dateRange"
            datepicker-placement="bottom"
            :state="inputStates('filters.dateRange')"
          />
        </asom-form-field>
      </div>
      <template #footer>
        <asom-button
          text="Reset Filters"
          variant="secondary"
          @click="resetFilters"
        />
        <asom-button text="Apply" @click="loadData(true)" />
      </template>
    </asom-card>
    <!-- filter end-->
    <article>
      <asom-alert
        class="mb-4"
        v-if="error"
        variant="error"
        :error-message="error"
      />
      <div class="w-full text-center" v-else-if="isLoading">
        <asom-icon icon="spinner" class="animate-spin" />
      </div>
      <asom-client-table v-else
        :data="list"
        :columns="[
          'referenceNo',
          'personNo',
          'createdBy',
          'dateModified',
          'time',
          'dws',
          'edit'
        ]"
        :sortableColumns="[
          'referenceNo',
          'personNo',
          'createdBy',
          'dateModified',
          'dws',
          'edit'
        ]"
        :searchableDateColumns="['dateCreated']"
        :searchableTimeColumns="['dateCreated']"
      >
        <template v-slot:header_dws>Shift</template>
        <template v-slot:header_personNo>Staff ID</template>
        <template v-slot:header_createdBy>Staff Name</template>
        <template v-slot:header_dateModified>Date</template>
        <template v-slot:referenceNo="{ rowData }">
          <asom-link @click="viewDetails(rowData)">{{rowData.referenceNo}}</asom-link>
          <div v-if="!rowData.status" class="hint-draft">Draft</div>
        </template>
        <template v-slot:dateModified="{data}">{{displayUtcDate(data)}}</template>
        <template v-slot:time="{rowData}">{{displayUtcTime(rowData.dateModified)}}</template>
        <template v-slot:edit="{ rowData }">
          <asom-button
              v-if="!rowData.status"
              text="Delete"
              size="sm"
              outline
              variant="error"
              @click="deleteFirstAidBoxClicked(rowData)"
            />
        </template>
      </asom-client-table>
    </article>

    <activity-log-list
      :data="transactions"
      title="Station Cash Collection Form Activity Log"
    />

    <asom-modal
      title="Delete draft content"
      v-model="showModal"
      :dismissible="false"
    >
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse">
        <div class="pl-4">
          <asom-button
            @click="onDeleteDraftSccf"
            text="OK"
            :loading="isSubmitting"
          />
        </div>
        <div>
          <asom-button
            @click="toggleModal(false)"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>

  </section>
</template>
<script>
import get from 'lodash.get'
import { mapGetters } from 'vuex';
import ActivityLogList from "../../_ActivityLogList.vue";

import { displayUtcDate, displayUtcTime } from '@/helpers/dateTimeHelpers'
import { getSCCFList, deleteDraftSccf } from '../../../../services/cashManagement.service';
import QuickInfo from "../../_QuickInfo.vue";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import moment from "moment";
import {toMoment} from "@/helpers/dateTimeHelpers";
import { setStorage, getStorage } from "@/helpers/sessionStorage";

export default {
  components: {
    ActivityLogList,
    QuickInfo
  },
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      error: null,
      isLoading: false,
      list: [],
      transactions: [],
      filters: {
        dateRange: {
          start: moment().subtract(1, "months").toDate(),
          end: moment().toDate(),
        }
      },
      isSubmitting: false,
      showModal: false,
      selectedItem: null,
    }
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
      canCreateSCCF: 'auth/canCreateSCCF'
    }),
    queryParams() {
      let params = {};
      if (
        this.filters.dateRange &&
        this.filters.dateRange.start &&
        this.filters.dateRange.end
      ) {
        params["searchDateFrom"] = this.parseDateTimeUTC(
          this.filters.dateRange.start,
          true
        );
        params["searchDateTo"] = this.parseDateTimeUTC(
          this.filters.dateRange.end,
          false
        );
      }
      return params;
    }
  },
  mounted() {
    const searchVal = getStorage("sccf-main");
    if(searchVal) {
      // UTC时间转换成对应的本地时间
      if(searchVal.dateRange && searchVal.dateRange.start)  searchVal.dateRange.start = moment.utc(searchVal.dateRange.start).toDate();
      if(searchVal.dateRange && searchVal.dateRange.end) searchVal.dateRange.end = moment.utc(searchVal.dateRange.end).toDate();
      this.filters = searchVal;
    }
    this.$nextTick(() => {
      this.loadData();
    })
  },
  methods: {
    displayUtcDate,
    displayUtcTime,
    resetFilters() {
      this.filters.dateRange = null;
    },
    async loadData( status) {
      if(status) setStorage("sccf-main", this.filters);
      this.error = null;
      this.isLoading = true;
      var params = {
        lineId: this.lineId,
        stationId: this.stationId,
        ...this.queryParams
      };
      const result = await getSCCFList(params);
      if (result.success) {
        this.list = get(result, 'payload.list', []);
        this.transactions = get(result, 'payload.transactions', []);
        let Logs = get(result, "payload.transactions", []);
        this.transactions = Logs.map(r => {
          const types = ["created", "edited", "deleted", "submitted"];
          return {
            message: `Transaction ${r.referenceNo} was ${types[r.transactionType]}`,
            officerName: r.createdBy,
            dateModified:  r.dateCreated,
          }
        })
      } else {
        this.error = result.payload;
      }
      this.isLoading = false;
    },
    create() {
      this.$router.push('sccf/create');
    },
    viewDetails({ id,status }) {
      if(status){
        this.$router.push({ name: 'SCCF Details', query: { id }});
      }else{
        this.$router.push({path: 'sccf/edit', query: { id }});
      }
    },
    parseDateTimeUTC(datetimeValue, isStartDate) {
      var d = toMoment(datetimeValue);
      if (d.isValid()) {
        var _d = isStartDate ? d.startOf("day") : d.endOf("day");
        const UTC_d = moment.utc(_d);
        return UTC_d.format("YYYY-MM-DD") + "T" + UTC_d.format("HH:mm:ss");
      }
      return "";
    },
    navigateToView(selectedForm) {
      if (
        selectedForm.formStatusEnum == 2 &&
        selectedForm.witnessId == this.userId
      ) {
        this.$router.push({
          name: "Cash Declaration Form Witness Sign",
          params: selectedForm,
        });
      } else if (
        selectedForm.formStatusEnum == 3 &&
        selectedForm.baggerId == this.userId
      ) {
        this.$router.push({
          name: "Cash Declaration Form Bagger Sign",
          params: selectedForm,
        });
      } else {
        this.$router.push({
          name: "Cash Declaration Form View",
          params: selectedForm,
        });
      }
    },
    deleteFirstAidBoxClicked(selectedItem) {
      this.selectedItem = selectedItem;
      this.toggleModal(true);
    },
    toggleModal(value) {
      this.showModal = value;
    },
    async onDeleteDraftSccf() {
      this.isSubmitting = true;
      const result = await deleteDraftSccf(this.selectedItem.id);
      if (result.success) {
        this.isSubmitting = false;
        this.toggleModal(false);
        this.loadData();
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.toggleModal(false);
        this.$scrollTop();
      }
    },
  }
}
</script>
